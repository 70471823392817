import React, { useEffect, useState } from "react";

import { PageContext } from "./contexts/PageContext";

import MainPage from "./pages/MainPage";
import WheelPage from "./pages/WheelPage";
import GiveawaysPage from "./pages/GiveawaysPage";
import GiveawayPage from "./pages/GiveawayPage";
import TasksPage from "./pages/TasksPage";
import ReferralPage from "./pages/RefferalPage";
import ProfilePage from "./pages/ProfilePage";
import LoadingPage from "./pages/LoadingPage";

import Menu from "./components/Menu";


function App() {
    const [ page, setPage ] = useState("loading");
    const [ pageComponent, setPageComponent ] = useState(undefined);

    useEffect(() => {
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.headerColor = "#1B4D35";
        window.Telegram.WebApp.backgroundColor = "#1B4D35";
    }, []);

    useEffect(() => {
        const appearContainer = document.getElementsByClassName("page-appear-container")[0];

        appearContainer.style.opacity = "100%";

        if(page==="main") {
            setTimeout(() => setPageComponent(<MainPage />), 250);
        } else if(page==="giveaways") {
            setTimeout(() => setPageComponent(<GiveawaysPage />), 250);
        } else if(page==="wheel") {
            setTimeout(() => setPageComponent(<WheelPage />), 250);
        } else if(page.startsWith("giveaway&")) {
            setTimeout(() => setPageComponent(<GiveawayPage />), 250);
        } else if(page==="tasks") {
            setTimeout(() => setPageComponent(<TasksPage />), 250);
        } else if(page==="referral") {
            setTimeout(() => setPageComponent(<ReferralPage />), 250);
        } else if(page==="profile") {
            setTimeout(() => setPageComponent(<ProfilePage />), 250);
        } else if(page==="loading") {
            setTimeout(() => setPageComponent(<LoadingPage />), 250);
        };

        setTimeout(() => appearContainer.style.opacity = "0%", 500);


        window.Telegram.WebApp.HapticFeedback.impactOccurred("light");

        if(page==="main") setTimeout(() => window.Telegram.WebApp.HapticFeedback.impactOccurred("heavy"), 200);
    }, [page]);

    return (
        <div id="App">
            <PageContext.Provider value={{page, setPage}}>
                <div className="page-appear-container"></div>

                {pageComponent}
                
                {
                    (
                        page!=="wheel" &&
                        page!=="loading" &&
                        !page.startsWith("giveaway&")
                    ) && 
                    <Menu/>
                }
            </PageContext.Provider>
        </div>
    );
};

export default App;