import React, { useEffect, useRef, useState } from 'react';

import Balance from '../components/MainPage/Balance';
import WheelButton from '../components/MainPage/WheelButton';
import MiningTimer from '../components/MainPage/MiningTimer';
import Loading from '../components/Loading';

function MainPage({ setPage }) {
	const [ isStandLoaded, setIsStandLoaded ] = useState(false);
	const appearContainerRef = useRef(null);

	useEffect(() => {
		window.Telegram.WebApp.BackButton.hide();
	}, []);

  return (
    <div id="main-page">
		<Balance />
		<WheelButton setPage={setPage} />

		<img
			style={isStandLoaded ? {} : {display: "none"}}
			src={localStorage.getItem("stand")}
			alt="Stand"
			id='stand'
			onLoad={() => setIsStandLoaded(true)}
		/> 
		{ !isStandLoaded && <Loading /> }

		<MiningTimer />
    </div>
  );
}

export default MainPage;
