import React, { useEffect, useState } from "react";

import { useSocket } from "../../contexts/SocketContext";

import Notification from "../Notification";

import CrossVector from "../../vectors/Cross";
import DecreaseBtnVector from "../../vectors/DecreaseBtn";
import IncreaseBtnVector from "../../vectors/IncreaseBtn";

function Popup({ setIsShowPopup, isShowPopup, number, setNotifications }) {
    const socket = useSocket();

    const [ ticketsNumber, setTicketsNumber ] = useState(1);
    const increaseTicketsNumber = () => setTicketsNumber(prev => prev+1);
    const decreaseTicketsNumber = () => setTicketsNumber(prev => prev>1 ? prev-1 : prev);

    const [ price, setPrice ] = useState(0);
    const [ balance, setBalance ] = useState(0);

    useEffect(() => {
        socket.on("giveaway", (data) => {
            setPrice(data.price);
        });

        socket.emit("getBalance");
        socket.on("balance", (data) => {
            setBalance(data.balance);
        });

        socket.on("notEnoughMoney", () => {
            return setNotifications([<Notification text="You haven't got enough BLTZ" color={"red"} />])
        });
    }, []);

    function buyTicket() {
        socket.emit("buyGiveawayTicket", {
            number: number,
            amount: ticketsNumber
        });

        setNotifications([ <Notification text={`You've bot ${ticketsNumber} tickets`} /> ]);
        setTimeout(() =>  setNotifications([]), 3000);
    };

    return (
        <div
            id="giveaway-popup"
            className={isShowPopup ? "opened" : isShowPopup===null ? "" : "closed"}
        >
            <div
                className="cancel-button"
                onClick={() => setIsShowPopup(false)}
            >
                {CrossVector}
            </div>

            <div className="popup-heading">
                <h1>Ticket</h1>
                <h3>Your balance: <span>{parseFloat(balance.toFixed(5))} $BLTZ</span></h3>
            </div>

            <div className="flex-row">
                <div className="price">
                    <h1>{price*ticketsNumber} <span>BLTZ</span></h1>
                </div>

                <div className="tickets-number">
                    <div onClick={decreaseTicketsNumber}>
                        {DecreaseBtnVector}
                    </div>
                    <h1>{ticketsNumber}</h1>
                    <div onClick={increaseTicketsNumber}>
                        {IncreaseBtnVector}
                    </div>
                </div>
            </div>

            <div
                className="buy-button"
                onClick={buyTicket}
            >
                Buy
            </div>
        </div>
    );
};

export default Popup;