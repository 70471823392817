import React, { useEffect, useState } from "react";

import Loading from "../components/Loading";

import { usePageContext } from "../contexts/PageContext";

function LoadingPage() {
    const { setPage } = usePageContext();

    const [ loaded, setLoaded ] = useState({
        stand: false,
        profileHat: false,
        lockedSafe: false,
        notificationBgGreen: false,
        notificationBgRed: false
    });

    useEffect(() => {

        // Load of the images
        fetchSvgAsBase64(`${process.env.PUBLIC_URL}/images/profile-hat.svg`)
        .then(base64 => {
            localStorage.setItem("profileHat", base64);
            setLoaded(prev => { prev["profileHat"] = true; return {...prev} })
        });
        fetchImageAsBase64(`${process.env.PUBLIC_URL}/images/stand.png`)
        .then(base64 => {
            localStorage.setItem("stand", base64);
            setLoaded(prev => { prev["stand"] = true; return {...prev} })
        });
        fetchImageAsBase64(`${process.env.PUBLIC_URL}/images/locked-safe.png`)
        .then(base64 => {
            localStorage.setItem("lockedSafe", base64);
            setLoaded(prev => { prev["lockedSafe"] = true; return {...prev} })
        });
        fetchImageAsBase64(`${process.env.PUBLIC_URL}/images/notification-bg-green.png`)
        .then(base64 => {
            localStorage.setItem("notificationBgGreen", base64);
            setLoaded(prev => { prev["notificationBgGreen"] = true; return {...prev} })
        });
        fetchImageAsBase64(`${process.env.PUBLIC_URL}/images/notification-bg-red.png`)
        .then(base64 => {
            localStorage.setItem("notificationBgRed", base64);
            setLoaded(prev => { prev["notificationBgRed"] = true; return {...prev} })
        });

        // Change the bg to black
        const previousBgImage = document.body.style.backgroundImage;
        document.body.style.backgroundImage = "none";

        // Return the default bg on component unmount
        return () => document.body.style.backgroundImage = previousBgImage;
    }, []);

    useEffect(() => {
        if(Object.values(loaded).every(state => state)) {
            setPage("main");
        };
    }, [loaded])

    return (
        <div id="loading-page">
            <Loading />
            <h2>Wow that's a lot to load...<br/>Downloaded {Object.values(loaded).reduce((a, status) => a + status, 0)} / {Object.values(loaded).length}</h2>
        </div>
    )
};


const fetchImageAsBase64 = async (path) => {
    const response = await fetch(path);
    const arrayBuffer = await response.arrayBuffer();

    const base64String = `data:image/png;base64,${arrayBufferToBase64(arrayBuffer)}`;

    return base64String;
};

const fetchSvgAsBase64 = async (path) => {
    const response = await fetch(path)

    const svgText = await response.text();
    const base64String = `data:image/svg+xml;base64,${btoa(svgText)}`;

    return base64String;
};

// Chat GPT generated
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};

export default LoadingPage;