export default (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="url(#paint0_linear_8_4965)" />
        <circle cx="14" cy="14" r="13.5" stroke="white" strokeOpacity="0.5" />
        <path d="M14 20C15.3881 20 16.7333 19.5187 17.8064 18.6381C18.8794 17.7574 19.6139 16.532 19.8847 15.1705C20.1555 13.8091 19.9459 12.3958 19.2915 11.1716C18.6372 9.9474 17.5786 8.98794 16.2961 8.45672C15.0136 7.92551 13.5867 7.8554 12.2583 8.25836C10.9299 8.66131 9.78239 9.51239 9.01118 10.6666C8.23998 11.8208 7.89283 13.2067 8.02889 14.5881C8.16495 15.9695 8.7758 17.2611 9.75736 18.2426" stroke="white" strokeLinecap="round" />
        <path d="M16.6667 12.6666L14.7355 14.9842C14.0799 15.7709 13.7521 16.1642 13.3115 16.1842C12.871 16.2042 12.5089 15.8421 11.7848 15.118L11.3334 14.6666" stroke="white" strokeLinecap="round" />
        <defs>
            <linearGradient id="paint0_linear_8_4965" x1="6.14634" y1="2.86364" x2="22.0199" y2="25.577" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74E2B4" />
                <stop offset="1" stopColor="#0FA968" />
            </linearGradient>
        </defs>
    </svg>
);