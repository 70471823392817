export default (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.5" cy="8.5" r="8" stroke="url(#paint0_linear_12_440)" />
        <path d="M8.5 12V7.5" stroke="url(#paint1_linear_12_440)" strokeLinecap="round" stroke-linejoin="round" />
        <circle cx="8.5" cy="5.5" r="0.5" fill="url(#paint2_linear_12_440)" />
        <defs>
            <linearGradient id="paint0_linear_12_440" x1="3.73171" y1="1.73864" x2="13.3692" y2="15.5289" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74E2B4" />
                <stop offset="1" stopColor="#0FA968" />
            </linearGradient>
            <linearGradient id="paint1_linear_12_440" x1="8.71951" y1="7.96023" x2="10.2885" y2="8.45913" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74E2B4" />
                <stop offset="1" stopColor="#0FA968" />
            </linearGradient>
            <linearGradient id="paint2_linear_12_440" x1="8.21951" y1="5.10227" x2="8.78642" y2="5.91346" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74E2B4" />
                <stop offset="1" stopColor="#0FA968" />
            </linearGradient>
        </defs>
    </svg>
);