import React, { useEffect, useState } from "react";

import { useSocket } from "../contexts/SocketContext";

import Loading from "../components/Loading";

import ProfileGreyVector from "../vectors/ProfileGrey";
import ProfileGreenVector from "../vectors/ProfileGreen";

function ProfilePage() {
    const socket = useSocket();

    const [ me, setMe ] = useState({});
    const [ leader, setLeader ] = useState({});

    const [ isHatLoaded, setIsHatLoaded ] = useState(false);
    const [ isSafeLoaded, setIsSafeLoaded ] = useState(false);

    useEffect(() => {
        socket.emit("getPosition");

        socket.on("position", (data) => {
            console.log(data.me)
            setMe(data.me);
            setLeader(data.leader);
        });
    }, []);

    return (
        <div id="profile-page">
            <div className="header">
                <img
                    className="bg"
                    src={localStorage.getItem("profileHat")}
                    alt=""
                    style={{display: isHatLoaded ? "" : "none"}}
                    onLoad={() => setIsHatLoaded(true)}
                />

                <div
                    className="profile-picture"
                    style={{display: isHatLoaded ? "" : "none"}}
                >
                    {ProfileGreenVector}
                </div>

                { !isHatLoaded && <Loading /> }
            </div>

            <div className="username">
                <h1>{me.username}</h1>
            </div>

            <div className="users-list">
                <div>
                    <h1 className="position">{1}</h1>
                    {ProfileGreyVector}
                    <h1 className="username">{leader.username}</h1>
                    <h1 className="reward">{leader.balance && parseFloat(leader.balance.toFixed(5))} $BLTZ</h1>
                </div>
                {
                    (me.telegramId !== leader.telegramId) &&
                    <div>
                        <h1 className="position">{me.position}</h1>
                        {ProfileGreyVector}
                        <h1 className="username">{me.username}</h1>
                        <h1 className="reward">{me.balance && parseFloat(me.balance.toFixed(5))} $BLTZ</h1>
                    </div>
                }
            </div>

            <div className="exchange">
                <img
                    src={localStorage.getItem("lockedSafe")}
                    alt=""
                    style={{ display: isSafeLoaded ? "" : "none" }}
                    onLoad={() => setIsSafeLoaded(true)}
                />
                { !isSafeLoaded && <Loading /> }
            </div>
        </div>
    );
};

export default ProfilePage;