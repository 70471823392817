import React, { useEffect, useState } from "react";

import { useSocket } from "../../contexts/SocketContext";

import CoinsVector from "../../vectors/Coins";

// Balance heading component
function Balance() {
    const socket = useSocket();

    const [ value, setValue ] = useState(0); // Balance in BLZ
    const [ usdtValue, setUsdtValue ] = useState(0); // Equivalent in USDT

    useEffect(() => {
        socket.emit("getBalance"); // Get initial balance value
        socket.on("balance", data => {
         	setValue(data.balance);
          setUsdtValue(data.usdtBalance);
        });

        socket.on("error", (err) => {
          	alert("Server Error, contact the developer");
        });
	}, []);

    return (
        <div id='balance'>
        <h2 className='balance--heading'>
          {CoinsVector}
          Balance
        </h2>
        <h1 className='balance--value'>
          <span>{parseFloat(value.toFixed(5))}</span>
          <span>$BLTZ</span>
        </h1>
        <h3 className='balance--usdt-value'>
          <span>{parseFloat(usdtValue.toFixed(5))}</span>
          <span>$USDT</span>
        </h3>
       </div>
    )
};

export default Balance;