import React, { useEffect, useState } from "react";

import { useSocket } from "../../contexts/SocketContext";

import UsdtLogoVector from "../../vectors/UsdtLogo";

function Reward() {
    const socket = useSocket();

    const [ reward, setReward ] = useState(0);
    const [ winners, setWinners ] = useState(0);

    useEffect(() => {
        socket.on("giveaway", (data) => {
            setReward(data.reward);
            setWinners(data.winnersNumber);
        });
    }, []);

    return (
        <div id="reward-container">
            {UsdtLogoVector}

            <h1>{reward} USDT</h1>
            <h3><span>{winners}</span> winners</h3>
        </div>
    );
};

export default Reward;