import React from "react";

import ProfileGreyVector from "../../vectors/ProfileGrey";

function Winner({ number, username }) {
    return (
        <div className="winner">
            <h1 className="position">{number}</h1>
            {ProfileGreyVector}
            <h1 className="username">{username}</h1>
        </div>
    )
};

export default Winner;