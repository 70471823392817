import React, { useEffect, useState } from "react";
import Task from "../components/TasksPage/Task";

import Notification from "../components/Notification";

import { useSocket } from "../contexts/SocketContext";

import TickVector from "../vectors/Tick";

function TasksPage() {
    const socket = useSocket();

    const [ tasks, setTasks ] = useState([]);
    const [ notifications, setNotifications ] = useState([]);

    useEffect(() => {
        socket.emit("getTasks");

        socket.on("tasks", (data) => {
            const receivedTasks = data.tasks;

            setTasks([...receivedTasks]);
        });

        socket.on("notSubscribedToChannel", (data) => {
            setNotifications([<Notification key={1} text="You're not subscribed" color="red"/>])
            setTimeout(() => setNotifications([]), 3000);
        });

        socket.on("rewardForTaskClaimed", (data) => {
            setNotifications([<Notification key={1} text={`You've earned ${data.reward} BLTZ`} />])
            setTimeout(() => setNotifications([]), 3000);
        });
    }, []);

    return (
        <div id="tasks-page">
            <div className="notifications-container">
                {notifications}
            </div>

            <div className="central-heading">
                <h1>
                    {TickVector}
                    Tasks
                </h1>
            </div>

            <div className="tasks-container">
               {tasks.map((task, index) => 
                <Task
                    key={Math.random()}
                    status={task.status}
                    telegramId={task.telegramId}
                    name={task.name}
                    description={task.description}
                    reward={task.reward}
                    link={task.link}
                />
            )}
            </div>

            <div className="eclipse" />
        </div>
    )
};

export default TasksPage;