import React from "react";

function LinesBg({ number }) {

    const lines = Array.from({ length: number }, (_, index) => (
        <div className="line" key={index}></div>
    ));

    return (
        <div className="lines-bg">
            {lines}
        </div>
    )
};

export default LinesBg;