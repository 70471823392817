import React from "react";

function Loading() {
    return (
        <div className="loading">
            <img src="./images/loading.png" alt="Loading" />
        </div>
    );
};

export default Loading;