export default (
    <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_58_1877)">
            <rect width="140" height="140" rx="70" fill="white" />
            <rect x="42" y="22" width="56" height="56" rx="28" fill="#2FCC8B" />
            <rect x="-35" y="93" width="210" height="210" rx="105" fill="#2FCC8B" />
        </g>
        <rect x="1.5" y="1.5" width="137" height="137" rx="68.5" stroke="white" strokeWidth="3" />
        <defs>
            <clipPath id="clip0_58_1877">
                <rect width="140" height="140" rx="70" fill="white" />
            </clipPath>
        </defs>
    </svg>
);