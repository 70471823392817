import React, { useEffect, useRef, useState } from "react";

import { useSocket } from "../../contexts/SocketContext";

import Notification from "../Notification";
import Loading from "../Loading";

import WheelArrowVector from "../../vectors/WheelArrow";
import WheelVector from "../../vectors/Wheel";
import SpinVector from "../../vectors/Spin";

function Wheel({ isActive, setNotifications }) {
    const socket = useSocket();

    const [ isButtonActive, setIsButtonActive ] = useState(true);

    function spinWheel() {
        if(isActive) socket.emit("spinWheel");
        else socket.emit("getWheelStarsPaymentInvoice");
    };

    useEffect(() => {
        socket.on("spinnedWheel", (data) => {
            setIsButtonActive(false);

            document.getElementById("wheel").style.transform = `rotate(${data.deg}deg)`;

            const prize = data.prize;

            setTimeout(async () => {
                setNotifications([<Notification text={`You've won ${prize.amount} ${prize.currency}`} />])
    
                setTimeout(() => {setNotifications([])}, 3000)
    
                setIsButtonActive(true);
            }, 5000);
        });


        socket.on("wheelStarsPaymentInvoice", (data) => {
            window.Telegram.WebApp.openInvoice(data.link, (status) => {
                if(status==="paid") {
                    socket.emit("spinWheel"); 
                };
            });
        });
    }, [socket]);

    return (
        <>
            <div
                id="wheel-container"
            >
                {WheelArrowVector}
                <div id="wheel">
                    {WheelVector}
                </div>
            </div>

            <div
                id="spin-btn"
                className={(isButtonActive) ? "" : "disabled"}
                onClick={(isButtonActive) ? spinWheel : () => {} }
            >
                {SpinVector}

                <span>Spin</span>
            </div>
        </>
    );
};

export default Wheel;