import React from "react";
import InfoVector from "../../vectors/Info";

function About() {
    return (
        <div className="about">
            <h4>
                {InfoVector}
                More:
            </h4>

            <p>
                Take part in the giveaway and try your luck. Buy tickets for <span>100 BLTZ</span> and increase your chances of winning. You may be the one who will be lucky, don't miss this opportunity
            </p>
        </div>
    );
};

export default About;