export default (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="url(#paint0_linear_8_4290)" />
        <circle cx="14" cy="14" r="13.5" stroke="white" strokeOpacity="0.5" />
        <mask id="path-3-inside-1_8_4290" fill="white">
            <path d="M13.8846 14.9302L17.75 6L8.23077 15.6744L11.5192 14.9302L8 22L12.8462 18.031L16.7115 15.6744L14.9808 19.7054L20 12.8217L13.8846 14.9302Z" />
        </mask>
        <path d="M17.75 6L35.1867 13.5473L4.20679 -7.32597L17.75 6ZM13.8846 14.9302L-3.55206 7.3829L-20.6758 46.944L20.0778 32.8925L13.8846 14.9302ZM20 12.8217L35.3523 24.0158L70.9867 -24.8557L13.8068 -5.14059L20 12.8217ZM14.9808 19.7054L-2.47797 12.2093L30.3331 30.8995L14.9808 19.7054ZM16.7115 15.6744L34.1703 23.1706L57.6632 -31.545L6.82108 -0.548367L16.7115 15.6744ZM12.8462 18.031L2.95569 1.80822L1.82865 2.49534L0.807447 3.3317L12.8462 18.031ZM8 22L-9.00915 13.5331L20.0387 36.6993L8 22ZM11.5192 14.9302L28.5284 23.3972L46.3656 -12.436L7.32554 -3.60117L11.5192 14.9302ZM8.23077 15.6744L-5.31244 2.34845L-50.7191 48.4953L12.4245 34.2058L8.23077 15.6744ZM0.313323 -1.54734L-3.55206 7.3829L31.3213 22.4776L35.1867 13.5473L0.313323 -1.54734ZM20.0778 32.8925L26.1932 30.784L13.8068 -5.14059L7.69138 -3.03206L20.0778 32.8925ZM4.6477 1.62765L-0.371532 8.51137L30.3331 30.8995L35.3523 24.0158L4.6477 1.62765ZM32.4395 27.2016L34.1703 23.1706L-0.747204 8.17827L-2.47797 12.2093L32.4395 27.2016ZM6.82108 -0.548367L2.95569 1.80822L22.7366 34.2538L26.602 31.8972L6.82108 -0.548367ZM0.807447 3.3317L-4.03871 7.3007L20.0387 36.6993L24.8849 32.7303L0.807447 3.3317ZM25.0092 30.4669L28.5284 23.3972L-5.48992 6.46332L-9.00915 13.5331L25.0092 30.4669ZM7.32554 -3.60117L4.03708 -2.85699L12.4245 34.2058L15.7129 33.4616L7.32554 -3.60117ZM21.774 29.0004L31.2932 19.326L4.20679 -7.32597L-5.31244 2.34845L21.774 29.0004Z" fill="white" mask="url(#path-3-inside-1_8_4290)" />
        <defs>
            <linearGradient id="paint0_linear_8_4290" x1="6.14634" y1="2.86364" x2="22.0199" y2="25.577" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74E2B4" />
                <stop offset="1" stopColor="#0FA968" />
            </linearGradient>
        </defs>
    </svg>
);