import React from "react";

import { usePageContext } from "../contexts/PageContext";

import TreeVector from "../vectors/Tree";
import UserIconVector from "../vectors/UserIcon";
import DocumentVector from "../vectors/Document";
import GiftVector from "../vectors/Gift";
import LightningVector from "../vectors/Lightning";

// Menu component
function Menu() {
    const { page, setPage } = usePageContext();

    function navigate(page) {
        setPage(page);
    }

    return (
        <div id="menu">
        <div
          className={'main-circle '+(page==="main"?"active":"")}
          onClick={() => {
            navigate("main");
          }}
        >
          {LightningVector}
        </div>

        <svg viewBox="0 0 343 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_5_55" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M172 54C196.301 54 216 34.3005 216 10C216 4.84285 219.788 0 224.945 0L328 0C336.284 0 343 6.71573 343 15V65C343 73.2843 336.284 80 328 80H15C6.71574 80 0 73.2843 0 65V15C0 6.71573 6.71573 0 15 0H119.055C124.212 0 128 4.84285 128 10C128 34.3005 147.699 54 172 54Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M172 54C196.301 54 216 34.3005 216 10C216 4.84285 219.788 0 224.945 0L328 0C336.284 0 343 6.71573 343 15V65C343 73.2843 336.284 80 328 80H15C6.71574 80 0 73.2843 0 65V15C0 6.71573 6.71573 0 15 0H119.055C124.212 0 128 4.84285 128 10C128 34.3005 147.699 54 172 54Z" fill="#093021"/>
        <path d="M215 10C215 33.7482 195.748 53 172 53V55C196.853 55 217 34.8528 217 10H215ZM224.945 1H328V-1H224.945V1ZM342 15V65H344V15H342ZM328 79H15V81H328V79ZM1 65V15H-1V65H1ZM15 1H119.055V-1H15V1ZM172 53C148.252 53 129 33.7482 129 10H127C127 34.8528 147.147 55 172 55V53ZM119.055 1C123.521 1 127 5.24586 127 10H129C129 4.43985 124.902 -1 119.055 -1V1ZM1 15C1 7.26801 7.26801 1 15 1V-1C6.16344 -1 -1 6.16344 -1 15H1ZM15 79C7.26802 79 1 72.732 1 65H-1C-1 73.8366 6.16346 81 15 81V79ZM342 65C342 72.732 335.732 79 328 79V81C336.837 81 344 73.8366 344 65H342ZM328 1C335.732 1 342 7.26801 342 15H344C344 6.16344 336.837 -1 328 -1V1ZM217 10C217 5.24586 220.479 1 224.945 1V-1C219.098 -1 215 4.43985 215 10H217Z" fill="url(#paint0_linear_5_55)" fillOpacity="0.2" mask="url(#path-1-inside-1_5_55)"/>
        <defs>
        <linearGradient id="paint0_linear_5_55" x1="221.5" y1="-10" x2="221.5" y2="113" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#999999" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>

        <div className='sub-circles-row'>

          <div className='sub-circles-group'>
            <div
              className={'sub-circle '+(page==="giveaways"?"active":"")}
              onClick={() => {
                navigate("giveaways");
              }}
            >
              {GiftVector}
            </div>
            <div
              className={'sub-circle '+(page==="tasks"?"active":"")}
              onClick={() => navigate("tasks")}
            >
              {DocumentVector}
            </div>
          </div>

          <div className='sub-circles-group'>
            <div
              className={'sub-circle '+(page==="referral"?"active":"")}
              onClick={() => navigate("referral")}
            >
              {TreeVector}
            </div>
            <div
              className={'sub-circle '+(page==="profile"?"active":"")}
              onClick={() => navigate("profile")}
            >
              {UserIconVector}
            </div>
          </div>

        </div>
        
      </div>
    );
};

export default Menu;