import React, { useEffect, useRef, useState } from "react";
import { formatTime } from "../../utils/time";

import { useSocket } from "../../contexts/SocketContext";

import GearVector from "../../vectors/Gear";
import LightningEmptyVector from "../../vectors/LightningEmpty";
import LinesBg from "../LinesBg";

// Mining timer component
function MiningTimer() {
    
    const socket = useSocket(); // socket object

    const [ isRunning, setIsRunning ] = useState(false); // Status state of the mining
    const [ remainingTime, setRemainingTime ] = useState({}); // The state with th object of the remaining time in hours, minutes and seconds
    const [ mined, setMined ] = useState(0); // Mined state

    const snakeRef = useRef(null); // Refference for the snake element

    useEffect(() => {
        socket.emit("getMining"); // Initial mining data request
        
        socket.on("mining", (data) => {
            // Fill states with data from the server
            setIsRunning(data.isRunning);
            setMined(data.mined);
            setRemainingTime(formatTime(data.remainingMilliseconds));
        
            // Set the snake bar width according to the mining progress
            const snakePrecent = data.isRunning ? 100-Math.round(data.remainingMilliseconds/(data.totalMilliseconds/100)) : 100;

            if(snakeRef.current !== null) snakeRef.current.style.width = `${30+(71/100*snakePrecent)}%`;
        });

        socket.on("error", (err) => {
            alert(err.msg);
        });
    }, []);

    // Handle click on the component
    // Collect mined currency to balance and start mining
    function collectAndStartMining() {
        socket.emit("startMining");

        window.Telegram.WebApp.HapticFeedback.impactOccurred("heavy");
        window.Telegram.WebApp.HapticFeedback.impactOccurred("heavy");
    };

    return (
        <div id="mining-timer" onClick={
            !isRunning ? 
            collectAndStartMining : () => {}
        }>
            {/* <img src="./images/mining-timer-bg.png" alt="" /> */}
            <LinesBg number={
                Math.ceil((window.innerWidth/100*90)/25)
            } />
            <div className='snake' ref={snakeRef}></div>

            <div className={"image-container " + (isRunning ? "running" : "")}>
                {GearVector}
                {LightningEmptyVector}
            </div>
            <div className="content">
                {
                    !isRunning ?
                    <>
                        <h1>Collect {mined.toFixed(5)} $BLTZ and start</h1>
                        <h3>Start now!</h3>
                    </> :
                    <>
                        <h1>Mining {mined.toFixed(5)} $BLTZ</h1>
                        <h3>
                            {remainingTime.hours||"0"}h &nbsp;
                            {remainingTime.minutes||"0"}m &nbsp;
                            {remainingTime.seconds||"0"}s
                        </h3>
                    </>
                }
            </div>
        </div>
    );
};

export default MiningTimer;