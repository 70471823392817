import React, { useEffect, useState } from "react";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

import Reward from "../components/GiveawayPage/Reward";
import Countdown from "../components/GiveawayPage/Countdown";
import About from "../components/GiveawayPage/About";
import Popup from "../components/GiveawayPage/Popup";
import Winners from "../components/GiveawayPage/Winners";

function GiveawayPage() {
    // Context states and setters
    const { setPage, page } = usePageContext();
    const socket = useSocket();

    // General state of the giveaway
    const [ isFinished, setIsFinished ] = useState(false);

    const [ isShowPopup, setIsShowPopup ] = useState(null); // State to show buy ticket popup
    const [ notifications, setNotifications ] = useState([]); // State with an array for notifications
    
    const number = Number(page.split("&")[1]); // Giveaway number

    useEffect(() => {
        // Telegram Back Button Functionality
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => setPage("giveaways"));
    
        socket.emit("getGiveaway", { number: number }); // Initial giveaway data request

        // Interval for the giveaway data
        const interval = setInterval(() => {
            socket.emit("getGiveaway", { number: number });
        }, 1000);

        socket.on("giveaway", (data) => {
            setIsFinished(data.finished)
        });

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div id="giveaway-page">
            <div className="notifications-container">
                {notifications}
            </div>

            <div className="heading">
                <h1>Giveaway</h1>
                <h1>№ {number}</h1>
            </div>

            <Reward />
            {
                isFinished ?
                <Winners /> :
                <>
                    <Countdown />
                    <About />

                    <div
                        id="participate-button"
                        onClick={() => setIsShowPopup(true)}
                    >
                        Participate
                        <div></div>
                    </div>

                    <Popup
                        setIsShowPopup={setIsShowPopup}
                        isShowPopup={isShowPopup}
                        number={number}
                        setNotifications={setNotifications}
                    />
                </>
            }
        </div>
    )
};

export default GiveawayPage;