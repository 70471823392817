import React from "react";

import LinesBg from "../LinesBg";

function Task({ title }) {

    return (
        <div className="task">
            <LinesBg number={
                Math.ceil((window.innerWidth/100*90)/25)
            } />

            <div className="image-container">
                <img src="./images/icons/telegram.png" alt="" />
            </div>
            <h1>{ title }</h1>
            <span>0/1</span>
        </div>
    )
};

export default Task;