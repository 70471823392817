export default (
    <svg viewBox="0 0 161 189" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_8_4925" fill="white">
            <path fillRule="evenodd" clipRule="evenodd" d="M80 61C102.644 61 121 42.6437 121 20C121 10.6443 127.336 0 136.691 0H146C154.284 0 161 6.71573 161 15V174C161 182.284 154.284 189 146 189H15C6.71573 189 0 182.284 0 174V15C0 6.71573 6.71573 0 15 0H23.3086C32.6643 0 39 10.6443 39 20C39 42.6437 57.3563 61 80 61Z" />
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M80 61C102.644 61 121 42.6437 121 20C121 10.6443 127.336 0 136.691 0H146C154.284 0 161 6.71573 161 15V174C161 182.284 154.284 189 146 189H15C6.71573 189 0 182.284 0 174V15C0 6.71573 6.71573 0 15 0H23.3086C32.6643 0 39 10.6443 39 20C39 42.6437 57.3563 61 80 61Z" />
        <path d="M120 20C120 42.0914 102.091 60 80 60V62C103.196 62 122 43.196 122 20H120ZM136.691 1H146V-1H136.691V1ZM160 15V174H162V15H160ZM146 188H15V190H146V188ZM1 174V15H-1V174H1ZM15 1H23.3086V-1H15V1ZM80 60C57.9086 60 40 42.0914 40 20H38C38 43.196 56.804 62 80 62V60ZM23.3086 1C27.5721 1 31.2238 3.42072 33.8507 7.07526C36.4802 10.7335 38 15.5288 38 20H40C40 15.1155 38.3519 9.91079 35.4747 5.90794C32.5948 1.90142 28.4008 -1 23.3086 -1V1ZM1 15C1 7.26801 7.26801 1 15 1V-1C6.16344 -1 -1 6.16344 -1 15H1ZM15 188C7.26802 188 1 181.732 1 174H-1C-1 182.837 6.16345 190 15 190V188ZM160 174C160 181.732 153.732 188 146 188V190C154.837 190 162 182.837 162 174H160ZM146 1C153.732 1 160 7.26802 160 15H162C162 6.16344 154.837 -1 146 -1V1ZM122 20C122 15.5288 123.52 10.7335 126.149 7.07527C128.776 3.42072 132.428 1 136.691 1V-1C131.599 -1 127.405 1.90142 124.525 5.90794C121.648 9.91079 120 15.1155 120 20H122Z" fill="url(#paint1_linear_8_4925)" fillOpacity="0.2" mask="url(#path-1-inside-1_8_4925)" />
        <defs>
            <linearGradient id="claim" x1="128.378" y1="237.431" x2="-48.5975" y2="218.328" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2A7455" />
                <stop offset="0.460309" stopColor="#28C584" />
                <stop offset="1" stopColor="#2EFFA8" />
            </linearGradient>
            <linearGradient id="active" x1="128.378" y1="237.431" x2="-48.5975" y2="218.328" gradientUnits="userSpaceOnUse">
                <stop stopColor="#184D37" />
                <stop offset="0.460309" stopColor="#126945" />
                <stop offset="1" stopColor="#23CF88" />
            </linearGradient>
            <linearGradient id="done" x1="128.378" y1="237.431" x2="-48.5975" y2="218.328" gradientUnits="userSpaceOnUse">
                <stop stopColor="#113425" />
                <stop offset="0.460309" stopColor="#0E5236" />
                <stop offset="1" stopColor="#1DA86E" />
            </linearGradient>

            <linearGradient id="paint1_linear_8_4925" x1="103.969" y1="-23.625" x2="103.969" y2="266.963" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);