import React, { useEffect, useRef, useState } from "react";
 
import Wheel from "../components/WheelPage/Wheel";
import Task from "../components/WheelPage/Task";
import Countdown from "../components/WheelPage/Countdown";
import Notification from "../components/Notification";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

import LogoVector from "../vectors/Logo";

function WheelPage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    const [ isActive, setIsActive ] = useState(true);

    const [ notifications, setNotifications ] = useState([]);

    useEffect(() => {
        window.Telegram.WebApp.BackButton.show();
        
        window.Telegram.WebApp.BackButton.onClick(() => setPage("main"));

        socket.emit("getWheel"); // Initial request

        socket.on("wheel", (data) => {
            setIsActive(data.isActive);
        });

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = isActive ? "auto" : 'hidden';
    }, [isActive]);

    return (
        <div id="wheel-page">
            <div className="notifications-container">
                {notifications}
            </div>

            <div className="central-heading">
                <h1>
                    {LogoVector}
                    Wheel of Fortune
                </h1>
                <h3>Complete all the tasks to spin the wheel of fortune.</h3>
            </div>

            <Wheel isActive={isActive} setNotifications={setNotifications} />

            <div id="tasks-container">
                <Task title="Subscribe to sponsor" />
                <Task title="Subscribe to sponsor" />
                <Task title="Subscribe to sponsor" />

                <div className={isActive ? "eclipse" : "blur "}>
                    { !isActive && <Countdown isActive={isActive} /> }
                </div>
            </div>
        </div>
    );
};

export default WheelPage;