import React, { useEffect, useRef, useState } from "react";

import { useSocket } from "../contexts/SocketContext";

import UserIconVector from "../vectors/UserIcon";
import PlusVector from "../vectors/Plus";

import Friend from "../components/ReferralPage/Friend";

function ReferralPage() {
    const socket = useSocket();

    const [referrals, setReferrals] = useState([]);
    const [totalIncome, setTotalIncome] = useState(0);

    const copyInviteLinkButtonRef = useRef(null);

    useEffect(() => {
        socket.emit("getReferrals");

        socket.on("referrals", (data) => {
            setReferrals(data.referrals);
            setTotalIncome(data.totalIncome);
        });
    }, []);

    function copyInviteLink() {
        const inviteLink = `${process.env.REACT_APP_APP_URL}?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`;

        if(navigator.share) {
            navigator.share({
                title: `Welcome to our app!\n${inviteLink}`
            });
        } else {
            navigator.clipboard.writeText(inviteLink);

            copyInviteLinkButtonRef.current.innerText = "Link copied";
            setTimeout(() => {
                copyInviteLinkButtonRef.current.innerText = "Share referral code";
            }, 3000);
        };
    };

    function shareStory() {
        const inviteLink = `${process.env.REACT_APP_APP_URL}?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`;

        window.Telegram.WebApp.shareToStory(
            process.env.REACT_APP_SERVER_URL+"/files/images/story.png",
            {
                text: `Welcome to our app!\n${inviteLink}`
            }
        );
    };

    return (
        <div id="referral-page">
            <div className="central-heading">
                <h1>Referral system</h1>
            </div>

            <div id="referral-card">
                <h3>Earned from referrals</h3>
                <h1>{totalIncome} <span>$BLTZ</span></h1>
            </div>

            <div className="secondary-text">
                <p>Get 10 $BLC immediately after the first mining of an invited friend and lots of different bonuses in the future</p>
            </div>

            <div className="list-header">
                {UserIconVector}
                <h4>{referrals.length} friends</h4>
            </div>

            <div className="users-list">
                {referrals.map(friend => 
                    <Friend username={friend.username} reward={friend.inviter.reward} />
                )}
            </div>

            <div id="invite-buttons">
                <button onClick={copyInviteLink} ref={copyInviteLinkButtonRef}>Share referral link</button>
                <button onClick={shareStory}>{PlusVector}</button>
            </div>

            <div className="list-eclipse"></div>
        </div>
    );
};

export default ReferralPage;