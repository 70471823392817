export default (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 15C0 6.71573 6.71573 0 15 0L85 0C93.2843 0 100 6.71573 100 15V85C100 93.2843 93.2843 100 85 100H15C6.71573 100 0 93.2843 0 85L0 15ZM42.6222 42.6998V36.1751H25.5756L25.5756 25.1584L73.2104 25.1584V36.3303L56.1638 36.3303V42.744C69.7248 43.5427 79.7423 46.5577 79.7423 50.14C79.7423 53.7363 69.6462 56.7609 56.0045 57.5453L56.0045 80.7075H42.6222L42.6222 57.5879C44.6795 57.742 46.7369 57.743 48.9505 57.7431C31.9244 57.7372 18.2472 54.3258 18.2472 50.14C18.2472 46.4879 28.6588 43.4254 42.6222 42.6998ZM42.6222 43.9872C28.9393 44.9111 21.77 48.6496 20.7962 50.14C21.9114 51.6916 31.1516 54.0191 48.9947 54.0191C66.9972 54.0191 76.078 51.6916 77.1932 50.14C76.2385 48.8116 69.3287 45.095 56.1638 44.0529V48.7435L56.1638 52.6226C53.9334 52.7778 51.5437 52.7778 49.154 52.6226C47.9959 52.6226 46.8377 52.5808 45.7242 52.5406C44.6933 52.5033 43.7006 52.4675 42.7815 52.4675V48.7435H42.6222V43.9872Z" fill="url(#paint0_linear_12_455)" />
        <defs>
            <linearGradient id="paint0_linear_12_455" x1="21.9512" y1="10.2273" x2="78.6425" y2="91.3465" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74E2B4" />
                <stop offset="1" stopColor="#0FA968" />
            </linearGradient>
        </defs>
    </svg>
);