import React from "react";

import ProfileGreyVector from "../../vectors/ProfileGrey";

function Friend({ username, reward }) {
    return (
        <div className="friend">
            {ProfileGreyVector}
            <h1 className="username">{username}</h1>
            <h1 className="reward">+{reward} $BLTZ</h1>
        </div>
    );
};

export default Friend;