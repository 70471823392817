import{ createContext, useContext, useEffect, useState } from 'react';
import io from "socket.io-client";

export const SocketContext = createContext({});

export function useSocket() {
    return useContext(SocketContext);
};

export function SocketProvider({ children }) {
    const [ socket, setSocket ] = useState(null);

    useEffect(() => {
        // Initialize the socket connection once when the provider is mounted
        const newSocket = io(process.env.REACT_APP_SERVER_URL, {
            auth: {
				initData: window.Telegram.WebApp.initData
			}
        });
        
        setSocket(newSocket);

        // Cleanup when the component unmounts
        return () => newSocket.close();
    }, []);

    return (
        <>
            {
                socket &&
                <SocketContext.Provider value={socket} >
                    {children}
                </SocketContext.Provider>
            }
        </>
    );
};