export default (
    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_12_503)">
            <rect width="35" height="35" rx="17.5" fill="white" />
            <rect x="10.5" y="6" width="14" height="14" rx="7" fill="#8F8F8F" />
            <rect x="-9" y="23.5" width="53" height="53" rx="26.5" fill="#8F8F8F" />
        </g>
        <defs>
            <clipPath id="clip0_12_503">
                <rect width="35" height="35" rx="17.5" fill="white" />
            </clipPath>
        </defs>
    </svg>
);