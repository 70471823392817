import React, { useRef, useState } from "react";

import { useSocket } from "../../contexts/SocketContext";

import TaskCardVector from "../../vectors/TaskCard";
import CoinsVector from "../../vectors/Coins";
import ProfileGreenVector from "../../vectors/ProfileGreen";

function Task({ status, telegramId, name, description, reward, link }) {
    const socket = useSocket();

    const buttonRef = useRef(null);

    function checkSubscription() {
        buttonRef.current.innerText = "Checking";

        socket.emit("checkChannelSubscription", { telegramId: telegramId });

        window.open(link, '_blank').focus();
    };

    function claimReward() {
        socket.emit("claimRewardForTask", { telegramId: telegramId });
    };

    return (
        <div className={"task " + status}>
            {TaskCardVector}
            
            <div className="profile-picture">
                {ProfileGreenVector}
            </div>

            <div className="content">
                <h1>{name}</h1>
                <h3>{description}</h3>
                <h2>
                    {CoinsVector}
                    +{reward} BLTZ
                </h2>
            </div>

            <div
                onClick={status==="active" ? checkSubscription : status==="claim" ? claimReward : () => {}}
                ref={buttonRef}
            >
                { status==="active" ? "Subscribe" : status==="claim" ? "Claim" : status==="check" ? "Checking" : "Done" }
            </div>
        </div>
    )
};

export default Task;