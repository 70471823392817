import React, { useEffect, useState } from "react";

import Winner from "./Winner";

import { useSocket } from "../../contexts/SocketContext";

function Winners() {
    const socket = useSocket();

    const [ winners, setWinners ] = useState([]);

    useEffect(() => {
        socket.on("giveaway", (data) => {
            setWinners(data.winners)
        });
    }, []);

    return (
        <div id="giveaway-winners">
            <div className="sub-header">
                <p>
                    <span>BlitzCoin</span> selected the winners among the participants and sent everyone a message about their winnings
                </p>
            </div>

            <div className="users-list">
                {
                    winners.map((winner, index) => {
                        return <Winner key={index} username={winner} number={index+1} />
                    })
                }
            </div>

            <div className="list-eclipse"></div>
        </div>
    );
};

export default Winners;