import React, { useEffect, useState } from "react";

import { formatTime } from "../../utils/time";

import { useSocket } from "../../contexts/SocketContext";

function Countdown() {
    const socket = useSocket();

    const [ remainingTime, setRemainingTime ] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    })

    useEffect(() => {
        socket.on("wheel", (data) => {
            setRemainingTime(formatTime(data.remainingTime));
        });
    }, []);
    
    return (
        <div
            id="wheel-countdown"
        >
            <div className="countdown">
                <h1>{remainingTime.hours>9 ? remainingTime.hours : `0${remainingTime.hours}`}</h1>
                <span>:</span>
                <h1>{remainingTime.minutes>9 ? remainingTime.minutes : `0${remainingTime.minutes}`}</h1>
                <span>:</span>
                <h1>{remainingTime.seconds>9 ? remainingTime.seconds : `0${remainingTime.seconds}`}</h1>
            </div>
            <h2>Next spin is in:</h2>
        </div>
    )
};

export default Countdown;