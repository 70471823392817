import React, { useEffect, useState } from "react";

import GiveawayCard from "../components/GiveawaysPage/GiveawayCard";

import { useSocket } from "../contexts/SocketContext";

function GiveawaysPage() {
    const socket = useSocket();

    const [ giveaways, setGiveaways ] = useState([]);

    useEffect(() => {
        window.Telegram.WebApp.BackButton.hide();

        socket.emit("getGiveaways");

        socket.on("giveaways", (data) => {
            setGiveaways(data.giveaways);
        });
    }, []);

    return (
        <div id="giveaways-page">
            <div className="heading">
                <h1>Participate</h1>
                <h1>in giveaways</h1>
            </div>


            <div id="giveaways-container">
                {
                    giveaways.map(giveaway => 
                        <GiveawayCard
                            key={giveaway.number}
                            giveaway={giveaway}
                        />
                    )
                }
            </div>

            <div className="eclipse" />
        </div>
    )
};

export default GiveawaysPage;