import React, { useEffect, useState } from "react";

import { formatTimeDays } from "../../utils/time";

import { useSocket } from "../../contexts/SocketContext";

function Countdown() {
    const socket = useSocket();

    const [ remainingTime, setRemainingTime ] = useState({
        days: 0,
        hours: 0,
        minutes: 0
    });

    useEffect(() => {
        socket.on("giveaway", (data) => {
            setRemainingTime(formatTimeDays(data.remainingTime))
        })
    }, []);

    return (
        <div id="giveaway-countdown">
            <div className="countdown">
                <h1>{remainingTime.days}</h1>
                <span>:</span>
                <h1>{remainingTime.hours>9 ? remainingTime.hours : `0${remainingTime.hours}`}</h1>
                <span>:</span>
                <h1>{remainingTime.minutes>9 ? remainingTime.minutes : `0${remainingTime.minutes}`}</h1>
            </div>
            <div>
                <span>Days</span>
                <span>Hours</span>
                <span>Minutes</span>
            </div>
        </div>
    );
};

export default Countdown;