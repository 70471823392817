import React from "react";

function Notification({ text, color }) {
    return (
        <div className={"notification"}>
            <img
                src={color==="red" ? localStorage.getItem("notificationBgRed") : localStorage.getItem("notificationBgGreen")}
                alt=""
            />
            
            <h1>{text}</h1>
        </div>
    )
};

export default Notification;