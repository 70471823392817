import React, { useEffect, useState } from "react";

import Loading from "../Loading";

import { usePageContext } from "../../contexts/PageContext";

function GiveawayCard({ giveaway }) {
    const { setPage } = usePageContext();

    const [ bgImage, setBgImage ] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.src = process.env.REACT_APP_SERVER_URL + "/files/images/card.png"

        img.onload = () => {
            setBgImage(img.src);
        };
    }, []);
    
    return (
        <div
            className="giveaway"
            style={
                { backgroundImage: `url("${bgImage}")` }
            }
            onClick={() => setPage("giveaway&"+giveaway.number)}
        >
            <div
                className="description"
                style={bgImage ? {} : {display: "none"}}
            >
                <h2>Giveaway №{giveaway.number}</h2>
                <h1>{giveaway.reward}<span>USDT</span></h1>
                <h3>Participants: {giveaway.participants}</h3>
            </div>
            {!bgImage && <Loading />}

            {
                giveaway.finished &&
                <div className="eclipse">
                    <h2>The giveaway is over &gt;</h2>
                </div>
            }
        </div>
    );
};

export default GiveawayCard;