import React, { useEffect, useState } from "react";

import { formatTime } from "../../utils/time";

import { useSocket } from "../../contexts/SocketContext";
import { usePageContext } from "../../contexts/PageContext";

import CalendarVector from "../../vectors/Calendar";

function WheelButton() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    const [ time, setTime ] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        socket.emit("getWheel");

        socket.on("wheel", (data) => {
            setTime(formatTime(data.remainingTime));
        });
    }, []);

    return (
        <div
            id="wheel-button"
            onClick={() => setPage("wheel")}
        >
            <div id="wheel-button--calendar">
                {CalendarVector}
            </div>
            <h3>
                {(time.hours>9 ? time.hours : `0${time.hours}`)+":" +
                (time.minutes>9 ? time.minutes : `0${time.minutes}`)+":" +
                (time.seconds>9 ? time.seconds : `0${time.seconds}`)}
            </h3>
        </div>
    )
};

export default WheelButton;